import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import Container from '../components/Layout/SharedStyles/Container';
import CollectionHero from '../components/Hero/CollectionHero';
import Lead from '../components/Lead/Lead';
import ProductGrid from '../components/Product/ProductGrid';
import ModuleArea from '../components/ModularContent/ModuleArea';

const CollectionPages = ({
  data: {
    datoCmsCollection: {
      title,
      id,
      metaTags,
      heroImage,
      heroVideo,
      heroIcon,
      leadHeading,
      leadText,
      products,
      productsHeading,
      modularContent,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper
      hasHero={true}
      breadcrumbs={breadcrumbs}
      pageData={pageContext}
      metaTags={metaTags}
    >
      {/* <ProductList products={allDatoCmsProduct} /> */}
      <CollectionHero image={heroImage} icon={heroIcon} video={heroVideo} />
      <Container width="large" gutters pt={4} pb={4}>
        <Lead heading={leadHeading} text={leadText} button={false} />
      </Container>
      <Container width="large" gutters pt={2} pb={2}>
        <ProductGrid products={products} heading={productsHeading} />
      </Container>
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default CollectionPages;

export const query = graphql`
  query CollectionPages($locale: String!, $id: String!) {
    datoCmsCollection(locale: { eq: $locale }, originalId: { eq: $id }) {
      id: originalId
      locale
      title
      metaTags {
        title
        description
        image {
          url
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "800", fit: "crop", w: "1920", q: 60 }
        )
        alt
      }
      heroVideo {
        url
      }
      heroIcon {
        url
      }
      leadHeading
      leadSubheading
      leadText
      productsHeading
      modularContent {
        ... on DatoCmsDetailedInfoBlock {
          ...DetailedInfoBlock
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsGalleryBlock {
          ...GalleryBlock
        }
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsCollectionsListBlock {
          ...CollectionsListBlock
        }
        ... on DatoCmsCardGridBlock {
          ...CardGridBlock
        }
      }
      products {
        ...ProductCard
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
