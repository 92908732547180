import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import style from './collection-hero.mod.scss';
import Container from '../Layout/SharedStyles/Container';

const CollectionHero = ({
  image,
  video = false,
  icon = false
}) => {
  return (
    <div className={style.collectionhero}>
      {video
        ? <Video video={video} />
        : <Image image={image} />
      }
      <div className={style.collectionhero__icon}>
        {icon && (
          <Container width={"large"} gutters>
            <div className={style.collectionhero__icon_container}><img src={icon.url} alt="icon" /></div>
          </Container>
        )}
      </div>
    </div>
  );
};

const Image = ({ image }) => {
  return (
    <div className={style.collectionhero__image}>
      {image && (
      <GatsbyImage
        className={style.collectionhero__image_gatsby}
        image={image.gatsbyImageData}
        alt={image.alt || 'Hero image'}
      />
      )}
    </div>
  );
}

const Video = ({ video }) => {
  return (
    <div className={style.collectionhero__video}>
      <video autoPlay muted loop playsInline src={video.url}/>
    </div>
  );
}

export default CollectionHero;